<template>
  <b-form @submit.prevent="onSubmit" class="pt-3">
    <b-form-group
      :invalid-feedback="getErrorMessage(errors, 'mobile')"
      :state="getErrorState(errors, 'mobile')"
    >
      <b-form-input
        :state="getErrorState(errors, 'mobile')"
        id="mobile"
        v-model="form.mobile"
        placeholder="Mobile Number"
        trim
      />
    </b-form-group>

    <b-button
      type="submit"
      variant="primary"
      class="text-uppercase text-white mt-5"
      pill
      block
    >
      <span> <b-icon-arrow-right scale="0.7" /> Continue </span>
    </b-button>
  </b-form>
</template>

<script>
import { variables } from "@/debug";
import { checkUserCredentials } from "../../apis";

export default {
  data() {
    return {
      form: {
        mobile: variables.mobile,
      },
      errors: null,
    };
  },
  methods: {
    onSubmit() {
      this.errors = null;
      const mobile = this.form.mobile;
      checkUserCredentials(mobile)
        .then((data) => {
          this.$emit("success", { ...data, mobile });
        })
        .catch(({ data }) => {
          this.errors = data.errors;
        });
    },
  },
};
</script>
