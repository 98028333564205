<template>
  <div>
    <!-- Title -->
    <b-card-title class="text-primary mb-3">Checkout</b-card-title>

    <!-- Alert -->
    <b-alert variant="info" dismissible show fade>
      Lets us verify you for a smooth checkout. Enter your mobile number.
    </b-alert>

    <check-user-credentials @success="successCheckCredentials" />
  </div>
</template>

<script>
import CheckUserCredentials from "../../../../../components/auth/CheckUserCredentials";

export default {
  components: { CheckUserCredentials },
  props: ["store"],
  methods: {
    successCheckCredentials(data) {
      const mobile = data.mobile;
      if (data.exists) {
        this.$router.push({ name: "CartAuthenticate", params: { mobile } });
      } else {
        this.$router.push({ name: "CartRegister", params: { mobile } });
      }
    },
  },
};
</script>
